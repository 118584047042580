import React from 'react'
import Layout from '../components/indexLayout'
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from 'gatsby'
import logoROHSGif from '../images/firstbond/Logo_ROHS.gif'
import sprinterJpg from '../images/firstbond/sprinter.jpg'
import phoneJpg from '../images/firstbond/Telefon.png'
import mailPng from '../images/firstbond/mail.png'

const ProductsPage = props => (
  <Layout>
    <div className="page white-bg">
      <div className="container">
        
        <section className="hero-section">

          <div className="hero-section-66">

            <p className="firstbond-header">
            Panneau composite aluminium FIRSTBOND
            </p>

            Le panneau composite aluminium  FIRSTBOND est doté d'un grand nombre d'avantages, comme son poids léger, la  haute résistance, la résistance aux impacts supérieurs, l'excellente  planéité de surface et surtout la résistance au climat.
                <p>
                    Le panneau composite aluminium FIRSTBOND peut être facilement traité,  scié, plié et rapidement installé.
                </p>
                <p>
                  {/*<img src={gammeJpg} width="701" height="120"/>*/}
                  <div className="hero-image">
                    <GatsbyImage
                      image={props.data.gamme.childImageSharp.gatsbyImageData}
                      alt="gamme image" />
                  </div>
                </p>
                {/*
                <p>
                    Il est élégant et magnifique, sa bonne flexibilité le rend  adapté aux designs variés.Il est aussi caractérisé par une <span class="gras">maintenance facile</span> et <span class="gras">un nettoyage simple</span>.
                </p>
                */}

                <p class="center gras 16 bleu ">NOUS VENDONS AUX ENTREPRISES, AUX INSTITUTIONS ET AUX PARTICULIERS</p>
                

                <div>
                    <p>
                      {/*<img src={couleursDisponiblesJpg} width="703" height="195"/>*/}
                      <div className="hero-image">
                        <GatsbyImage
                          image={props.data.couleursDisponibles.childImageSharp.gatsbyImageData}
                          alt="couleurs disponibles image" />
                      </div>                      
                    </p>
                </div>

                {/*
                <p class="center bleu gras">Couleurs disponibles</p>
                <p>
                  <div className="hero-image">
                    <GatsbyImage
                      image={props.data.couleur.childImageSharp.gatsbyImageData}
                      alt="couleur image" />
                  </div>
                </p>
                */}

                <p><span class="bleu gras">Le panneau composite aluminium  FIRSTBOND</span> peut être utilisé pour la décoration des parois internes, le placage des  basses constructions, la décoration intérieure comme éxtérieure.</p>
                <p>
                  Il est également idéal pour:
                  <ul>
                    <li>
                      le marquage moderne de commerces
                    </li>
                    <li>
                      les panneaux publicitaires
                    </li>
                    <li>
                      la crédence cuisine ou salle de bain
                    </li>
                    <li>
                      la construction de remorques, foodtruck, caravanes
                    </li>
                    <li>
                      le contrecollage de photographies ou de peinture artistique
                    </li>
                  </ul> 
                </p>

                <p>
                  <div className="hero-image">
                    <GatsbyImage
                      image={props.data.miroirOrEtArgent.childImageSharp.gatsbyImageData}
                      alt="miroirs or argent image" />
                  </div>                      
                </p>
                <p>
                  <div className="hero-image">
                    <GatsbyImage
                      image={props.data.goldCuivreBrosse.childImageSharp.gatsbyImageData}
                      alt="or cuivre brossé image" />
                  </div>                      
                </p>

                <p>Consultez le service client pour les délais de livraison</p>
          </div>

          
          <div className="right-panel-fixed-size border-left border-top">
            <img src={sprinterJpg} width="156" height="128"/><br/>
            <p>Consultez le service client pour les délais de livraison.</p>
            <div id="contact">contactez-nous</div>
            <div>
              <img className="small-border-top" src={phoneJpg} width="20" height="18"/><a className="small-border-left" href="tel:+33984417916">09 84 41 79 16</a><br/>
              <div>(Prix d'un appel local)</div>
              <img className="small-border-top" src={mailPng} width="20" height="18"/><a className="small-border-left" href="mailto:contact@firstbond.boutique">contact@firstbond.boutique</a><br/>
            </div>
            <p>SERVICE CLIENT</p>
            <p>Pour répondre à toutes vos questions</p>
            <p>Du lundi au vendredi <br/>
              9h00-19h00</p>
            <p>FIRSTBOND FRANCE</p>
          </div>
        
      </section>

      <section className="hero-section">
        <div className="info-area">
          <div className="logo-rohs"><img src={logoROHSGif} alt="ROHS" width="121" height="65"/></div>
          <div className="mention-rohs">
            Tous nos produits sont certifiés CE et à la norme RoHS.
          </div>
        </div>
      </section>

      </div>

    </div>

  </Layout>
)

export const firstSiteImages = graphql`fragment firstSiteImages on File {
  childImageSharp {
    gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
  }
}
`

export const pageQuery = graphql`
  query {
    couleur: file(relativePath: { eq: "images/firstbond/couleur.jpg" }) {
      ...firstSiteImages
    }
    couleursDisponibles: file(relativePath: { eq: "images/firstbond/couleurs_disponibles.jpg" }) {
      ...firstSiteImages
    }
    gamme: file(relativePath: { eq: "images/firstbond/gamme.jpg" }) {
      ...firstSiteImages
    }
    miroirOrEtArgent: file(relativePath: { eq: "images/firstbond/miroir-or-et-argent.jpg" }) {
      ...firstSiteImages
    }
    goldCuivreBrosse: file(relativePath: { eq: "images/firstbond/gold-Cuivre-brosse.jpg" }) {
      ...firstSiteImages
    }    
  }
`


export default ProductsPage
